/* eslint-disable no-underscore-dangle */
/**
 * Prevents Angular change detection from
 * running with certain Web Component callbacks
 */
(window as any).__Zone_disable_customElements = true;

// avoid change detection on timer calls and XHR requests
// (window as any).__Zone_disable_timers = true;
(window as any).__Zone_disable_XHR = true;

// (window as any).__Zone_disable_requestAnimationFrame = true;
// (window as any).__Zone_disable_PromiseRejectionEvent = true;

// (window as any).__Zone_ignore_on_properties = [];

// disable on properties
// eslint-disable-next-line
// const targets = [WebSocket.prototype];
// targets.forEach((target) => {
//   (window as any).__Zone_ignore_on_properties.push({
//     target,
//     ignoreProperties: ['close', 'error', 'open', 'message']
//   });
// });
//
// // disable addEventListener

// (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['message', 'visibilitychange'];

// (window as any).__Zone_disable_ZoneAwarePromise = true;
